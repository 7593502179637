import { CoverImageFragment } from "@src/fragments.generated";
import CoverImagePickerEmojiTab from "@components/ui/CoverImagePickerEmojiTab";
import CoverImagePickerImagesTab from "@components/ui/CoverImagePickerImagesTab";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import SegmentedControl from "@src/deprecatedDesignSystem/components/SegmentedControl";
import { MediaFragment, useFileUpload } from "@hooks/useFileUpload";
import { BackgroundGradient, CoverImageInput } from "@src/types.generated";
import { StyleSheet } from "aphrodite";
import React, { FC, useState } from "react";

type Props = {
  coverImage?: CoverImageFragment;
  onCoverImageSelected?: (coverImage: Omit<CoverImageInput, "id">) => void;
};

const CoverImagePicker: FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ coverImage, onCoverImageSelected }, ref) => {
    const [tab, setTab] = useState("Images");
    const [emoji, setEmoji] = useState("");
    const [selectedGradient, setSelectedGradient] =
      useState<BackgroundGradient | null>(null);

    const updateImage = (url: string) => {
      if (!onCoverImageSelected) return;
      onCoverImageSelected({
        mediaUrl: url,
        emojiIcon: null,
      });
      setEmoji("");
      setSelectedGradient(null);
    };

    const [onFileSelected, { loading: fileUploadInProgress }] = useFileUpload(
      (e: MediaFragment) => {
        if (!onCoverImageSelected) return;
        onCoverImageSelected({
          mediaUrl: e.url,
          emojiIcon: null,
        });
        setEmoji("");
        setSelectedGradient(null);
      },
    );

    const updateGradient = (gradient: BackgroundGradient) => {
      if (!onCoverImageSelected) return;
      setSelectedGradient(gradient);
      onCoverImageSelected({
        emojiIcon: emoji || coverImage?.emojiIcon,
        background: gradient,
        mediaUrl: null,
      });
    };

    const updateEmoji = (emojiIcon: string) => {
      setEmoji(emojiIcon);
      if (!onCoverImageSelected) return;
      onCoverImageSelected({
        emojiIcon: emojiIcon,
        background: selectedGradient || coverImage?.background,
        mediaUrl: null,
      });
      setSelectedGradient(null);
    };

    const clearEmoji = () => {
      if (!onCoverImageSelected) return;
      onCoverImageSelected({
        emojiIcon: null,
        background: selectedGradient || coverImage?.background,
      });
      setEmoji("");
    };

    return (
      <AutoLayout
        ref={ref}
        direction="vertical"
        alignmentHorizontal="center"
        alignmentVertical="top"
        styleDeclaration={styles.container}
      >
        <SegmentedControl
          buttonWidth="175px"
          selectedOption={tab}
          options={[
            { value: "Images", label: "Images" },
            { value: "Emoji", label: "Emoji" },
          ]}
          onOptionSelected={(option) => setTab(option)}
        />
        {tab === "Images" && (
          <CoverImagePickerImagesTab
            updateImage={updateImage}
            onFileSelected={onFileSelected}
            fileUploadInProgress={fileUploadInProgress}
          />
        )}
        {tab === "Emoji" && (
          <CoverImagePickerEmojiTab
            coverImage={coverImage}
            selectedGradient={selectedGradient}
            setSelectedGradient={updateGradient}
            emoji={emoji}
            setEmoji={updateEmoji}
            clearEmoji={clearEmoji}
          />
        )}
      </AutoLayout>
    );
  },
);

export default CoverImagePicker;

const styles = StyleSheet.create({
  container: {
    height: 425,
    borderRadius: 16,
    padding: 16,
  },
  imageTabContainer: {
    height: 350,
    width: 350,
    maxHeight: 350,
    overflow: "scroll",
    marginTop: 15,
  },
  emojiTabContainer: {
    height: 300,
    width: 350,
    maxHeight: 300,
    marginBottom: 15,
  },
  imageSearchContainer: {
    width: 340,
  },
  imageContainer: {
    margin: "0px 0px 2px 0px",
  },
  images: {
    height: 82,
    width: 82,
    padding: 0,
  },
  uploadFileSection: {
    width: "100%",
    height: 75,
    border: "2px dashed",
    borderColor: deprecatedTones.gray5Alpha,
    borderRadius: 8,
    backgroundColor: deprecatedColors.surface,
  },
  fileUploadImageContainer: {
    height: 87,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
  },
  spinner: {
    marginBottom: 8,
  },
  emojiContainer: {
    height: 350,
    maxHeight: 350,
    overflow: "scroll",
  },
  draggingFile: {
    border: `2px dashed ${deprecatedTones.gray5}`,
  },
  draggingFileOverDropArea: {
    focus: {
      boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    focusError: {
      boxShadow: `0 0 0 3px ${deprecatedColors.errorContainer}`,
      border: `1px solid ${deprecatedTones.red11}`,
    },
    dropdownShadow: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.03)",
    },
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  clearButton: {
    fontColor: deprecatedColors.primary,
  },
});
